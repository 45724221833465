import React, { Suspense } from 'react';
import ModelLoader from './ModelLoader';
import { folder, useControls } from 'leva';

export default function ModelsSetup({group_, name_, models }) {
    const controlsConfig = { visible: false }
    const controls = useControls(group_, { [name_]: folder(controlsConfig) });

   
    return (
        <>
            {controls.visible && 
                <group >
                    {models.map((model) => (
                        <Suspense fallback={null} key={model.name}>
                            <ModelLoader group_={group_+'.'+name_} name_={model.name} url={model.url} />
                        </Suspense>
                    ))}
                </group>
            }
        </>
    );
}
