import { useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import TWEEN from 'tween';

const SidebarScene = ({ objectDetailsObject }) => {
    
    const ref = useRef()


    return (
        <Canvas  flat shadows gl={{ antialias: true }} camera={{ position: [0, 7000, 0], near: 0.5, far: 10000, fov: 35 }}  >
        <directionalLight position={[1,200,-300]} intensity={2.5}/>
        <directionalLight position={[1,200,300]} intensity={2.5}/>
        <ambientLight intensity={1.5}/> 
        <OrbitControls ref={ref} enableZoom={true} enablePan={false} />
        <SidebarObject objectDetailsObject={objectDetailsObject} controls={ref} />
        </Canvas>
    );
};

export default SidebarScene;

const SidebarObject=({objectDetailsObject,controls})=>{
    const { camera, scene } = useThree();
    const objectRef = useRef();
  
    useEffect(() => {
      // Clear the scene
      while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
      }

      if(!objectDetailsObject) return
  
      // Clone the object
      //objectDetailsObject.material =  objectDetailsObject.userData['original_material']
      const clonedObject = objectDetailsObject.clone();
      scene.add(clonedObject);
      objectRef.current = clonedObject;

      
  
      // Adjust camera to fit the object
      const box = new THREE.Box3().setFromObject(clonedObject);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());

      console.log(center)
  
      new TWEEN.Tween(controls.current.target)
        .to(
            {
                x: center.x,
                y: center.y,
                z: center.z,
            },
            1100
        )
        .easing(TWEEN.Easing.Cubic.Out)
        .start()

    new TWEEN.Tween(camera.position)
        .to(
            {
                x: center.x,
                y: center.y+100,
                z: center.z+10,
            },
            1000
        )
        .easing(TWEEN.Easing.Cubic.Out)
        .start()

    console.log(objectDetailsObject.userData['original_material'])
    }, [objectDetailsObject, camera, scene]);

    return(null)
}
