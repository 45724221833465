import { useControls } from "leva"
import { ModelListPK } from "./Utils/ModelsList.js"
import { Suspense, useEffect } from "react";
import ModelsSetup from "./Utils/ModelsSetup.js";
import BlockModels from "./Utils/BlockModels.js";
import BlastPlan from "./production_plan/BlastPlan.js";
import HolesLoader from "./Utils/HolesLoader.js";


export default function EurekaModels() {
    const name_ = 'EurekaModels'
    const {visible} = useControls(name_, {visible:false});
    useEffect(()=>{
        console.log('Eureka Models rerendered')
    },[])
    return (
        <>
        
        { visible && 
            <group>
                <HolesLoader/>
                <BlockModels url='./models/blockmodel/au_eureka_blockmodel.zip' name='BM' dx={0} dy={0} dz={0} size='1mb' grade='AU'/>
                <BlastPlan/>
                {ModelListPK.map((model) => (
                    <Suspense fallback={null} key={model.name}>
                        <ModelsSetup group_={name_} name_={model.name} models={model.models} />                        
                    </Suspense>
                ))}
            </group>
            
        }
        </>
    )
}
