import { StrictMode, useEffect, useRef, useState } from 'react'
import React from 'react';
import {Canvas} from '@react-three/fiber'
import { Perf } from 'r3f-perf';
import { OrbitControls } from '@react-three/drei';
import Experience from './Experience.js'
import { Leva } from 'leva';
import Measurements from './clickTools/Measurements.js';
import Info from './clickTools/Info.js';
import CameraControl from './clickTools/CameraControl.js';
import ColorChange from './clickTools/ColorChange.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRotate, faTimes } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LeftBar from './components/sidebar/LeftBar.js';
import { useSidebarStore } from './store/sidebarStore.js';


export default function App()
{ 
    const { toggleComponent } = useSidebarStore();

    useEffect(()=>{
        console.log('rendered')
    })

    const ref = useRef()
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
  
    return (
        <>
      <div className="relative flex h-full">
        <div className={`fixed top-0 left-0 h-full transform transition-transform  duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-full'} bg-gray-100 w-1/4 z-50 max-w-[300px] min-w-[100px]`}>
            <LeftBar/>  
        </div>
  
        <button 
          onClick={toggleSidebar} 
          className={`fixed top-1 hover:bg-blue-500 bg-gray-400 bg-opacity-75 text-white flex items-center justify-center rounded-md z-50 w-4 h-6 mx-1`}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="sm" />
        </button>

        <button 
          onClick={toggleComponent} 
          className={`fixed top-10  hover:bg-blue-500 bg-gray-400 bg-opacity-75 text-white flex items-center justify-center rounded-md z-50 w-4 h-6 mx-1`}
        >
          <FontAwesomeIcon icon={faRotate} size="sm" />
        </button>
  
        <div className="flex-1 z-40">

            <Leva className="relative flex-1 z-40 w-1/5 max-w-[50px]" theme={{
              colors: {
                elevation1: '#1c1c1c', // background color of the main panel
                elevation2: '#292929', // background color of the folders
                elevation3: '#346379', // background color of the inputs
                highlight1: '#d4af37', // highlight color (e.g., for active items)
                highlight2: '#d4af37', // secondary highlight color,
                highlight3: ' #fff',
                vivid1: '#d4af37', // vivid color
                accent1: '#ffffff', // color for text and labels
                accent2: '#fff', // secondary text color
                accent3:'#fff'
              },
              sizes: {
                rowHeight:'15px',
                checkboxSize:'10px',
                rootWidth: 'fit', // width of the Leva panel
                controlWidth:'fit',
                controlHeight: 'fit', // height of the controls
              },
              fonts: {

                root: '8px Arial, sans-serif', // font size and type
                label: '8px Arial, sans-serif', // font size and type for labels
              },
              radii: {                
                sm: '0px', // small radius for rounded corners
                md: '0px', // medium radius for rounded corners
              }

              }}/>   
              

            <StrictMode>
                <Canvas flat shadows gl={{ antialias: true }} camera={{ position: [0, 7000, 0], near: 0.5, far: 10000, fov: 35 }}  className="h-full">
                    {/* <Perf  className="perf"/> */}
                    <OrbitControls makeDefault ref={ref}/>                    
                    <Experience/>    
                    <CameraControl controls={ref} />                   
                </Canvas>
            </StrictMode>       

        </div>
        
          
    </div>
        {/* <DetailsSideBar/> */}
        <Measurements/>
        <Info/>
        <ColorChange/>  
    </>
  );
};