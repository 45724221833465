import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MultiSelectDropdown = ({label, list, selectedlist, itemClick }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleDropdown = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="relative">
        <div onClick={toggleDropdown} className="cursor-pointer border-solid p-2 border-stone-500 shadow-lg w-full border flex justify-between items-center text-xs">
            <label>{label}</label>
            <FontAwesomeIcon icon={faChevronDown} className="text-sm" />
        </div>
        {isVisible && (
            <div className="absolute shadow-lg p-2 bg-fuchsia-100 z-40 w-full left-0 rounded max-h-select overflow-y-auto border-stone-500 border-solid border">
                <div className="mg-1 block w-full">
                    {list.map((item, key) => {
                        const isSelected = selectedlist.includes(item);
                        return (
                            <div
                                key={key}
                                className={`cursor-pointer w-full border-gray-100 rounded-t border-b ${isSelected ? 'bg-green-200 hover:bg-pink-500' : 'bg-white hover:bg-teal-400'}`}
                                onClick={() => itemClick(item)}
                            >
                                <div className={`flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative ${isSelected ? 'hover:bg-pink-500' : 'hover:border-teal-100'}`}>
                                    {item}                                            
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        )}
    </div>
    
    );
};




export default MultiSelectDropdown;