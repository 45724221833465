import React, { useEffect, useRef } from 'react'
import { useClickStore } from '../store/clickStore';
import { useShallow } from 'zustand/react/shallow';
import html2canvas from 'html2canvas';

export default function Measurements() {

    const ref = useRef()

    const {clickType,setClickType,setZoomToExtent} = useClickStore(useShallow((state)=>({
        clickType:state.clickType,
        setClickType:state.setClickType,
        setZoomToExtent:state.setZoomToExtent
    })))


    useEffect(() => {
        
        console.log('click type changed',clickType)
        // Define cursor styles for each click type
        const cursorStyles = {
            pan: 'pointer',
            info: 'help',
            distance: 'crosshair',
            zoom: 'zoom-in',
            color: 'move'
        };

        // Update the cursor style based on the current click type
        document.body.style.cursor = cursorStyles[clickType] || 'default';

        // Clean up on unmount
        return () => {
            document.body.style.cursor = 'default';
        };
    }, [clickType]);

    const handleButtonClick = (id) => {
        setClickType(id);
    };

    const takeScreenshot = () => {
        //const input = document.getElementById("divToPrint");
        html2canvas(document.body).then(canvas => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png', 1.0); // High-quality PNG
          link.download = 'screenshot.png';
          link.click();
        });
      };

      const takeScreenshote = async () => {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            video: { mediaSource: 'screen' }
          });
    
          const track = stream.getVideoTracks()[0];
          const imageCapture = new ImageCapture(track);
          const bitmap = await imageCapture.grabFrame();
          track.stop();
    
          const canvas = document.createElement('canvas');
          canvas.width = bitmap.width;
          canvas.height = bitmap.height;
          const context = canvas.getContext('2d');
          context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
    
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = 'screenshot.png';
          link.click();
        } catch (err) {
          console.error('Error capturing screen:', err);
        }
      };
    

  return (
    <div id='actions' ref={ref}>
        <button 
            onClick={() => setZoomToExtent(true)}
        >E</button>
        <button 
            onClick={() => handleButtonClick('pan')}
            className={clickType === 'pan' ? 'active' : ''}
        >P</button>
        <button 
            onClick={() => handleButtonClick('info')}
            className={clickType === 'info' ? 'active' : ''}
        >I</button>
        <button 
            onClick={() => handleButtonClick('distance')}
            className={clickType === 'distance' ? 'active' : ''}
        >D</button>
        <button 
            onClick={() => handleButtonClick('zoom')}
            className={clickType === 'zoom' ? 'active' : ''}
        >Z</button>
        <button 
            onClick={() => handleButtonClick('color')}
            className={clickType === 'color' ? 'active' : ''}
        >C</button>
    </div>
  )
}

