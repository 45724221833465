import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as THREE from 'three';
import { useControls  } from 'leva';
import dataCache from './dataCache.js'; 
import JSZip from 'jszip';
import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import BlockModelInfo from './BlockModelInfo.js';

export default function BlockModels({ name, url , dx ,dy ,dz , size  , grade }) {
  const name_ = 'EurekaModels.BlockModels.' + name;
  const [{ bmvisible , interval},set] = useControls(name_, ()=>({ bmvisible: false , interval:{ min: 0.7,
    max: 14.5,value: [1, 2],step:0.1}}));

  const instancedMeshRef = useRef();
  const [ogdata, setOGData] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const temp = new THREE.Object3D();
  const color = new THREE.Color();

  // Load data when component mounts
  useEffect(() => {
    if(!bmvisible) return
    console.log('model search');
      const fetchData = async () => {
        if (dataCache[url]) {
          console.log('local data found');
          setOGData(dataCache[url])
        }else{
          const userConfirmed = window.confirm(`Block model is not available on your cache , do you want to download it @ ${size}?`);
          if (userConfirmed) {
            console.log('downloading data')

            //////////////
            try {
              const blob = await fetchDataWithProgress(url);
              console.log('Download complete. Extracting...');
              const zip = await JSZip.loadAsync(blob);
              const fileName = Object.keys(zip.files)[0]; // Assuming there's only one file in the zip
              const fileData = await zip.file(fileName).async('string');
              const parsedData = d3.csvParse(fileData);
          
              setOGData(parsedData);
              dataCache[url] = parsedData; 
              
            } catch (error) {
              console.error('Error loading the zipped CSV file:', error);
            }
            
          }else{
            set({ bmvisible: false  });
          }
        
        }
      }

    fetchData()
    
  }, [url,bmvisible]); // Only load data when URL changes

  useEffect(()=>{
    
    let tempArr = [];
    for (let i = ogdata.length - 1; i >= 0; i--) {
      // get the current object
      // check if the object's grade is within the range
      if (ogdata[i][grade] >= interval[0] && ogdata[i][grade]  <= interval[1]) {
        tempArr.push(ogdata[i])
      }
    }
    setData(tempArr)
    setCount(tempArr.length); 
  },[ogdata,interval])

  // Memoize colors calculation
  const colors = useMemo(() => {
    if (data.length === 0) return new Float32Array();
    const colorsArray = new Float32Array(data.length * 3);
    for (let i = 0; i < data.length; i++) {
      const value = parseFloat(data[i][grade]);
      if (value >= 5) {
        color.set(0x800080) // PURPLE
      } else if (value >= 2.5) {
        color.set(0xb45f06) // BROWN
      } else if (value >= 1.5) {
        color.set(0xcc0000) // RED
      } else if (value >= 0.75) {
        color.set(0x38761d) // GREEN
      } else{
        color.set(0xf1c232) // YELLOW
      }
      colorsArray[i * 3] = color.r;
      colorsArray[i * 3 + 1] = color.g;
      colorsArray[i * 3 + 2] = color.b;
    }
    return colorsArray;
  }, [data,bmvisible]);

  useEffect(() => {
    console.log('visibility toggled')
    if (data.length > 0 && instancedMeshRef.current) {
      for (let i = 0; i < data.length; i++) {
        const x = data[i].XC - 259333 + dx;
        const y = data[i].ZC - 1120 +dy;
        const z = 8156513 - data[i].YC +dz;
        temp.position.set(x, y, z);
        temp.updateMatrix();

        instancedMeshRef.current.setMatrixAt(i, temp.matrix);
      }

      // Update the instance
      instancedMeshRef.current.instanceMatrix.needsUpdate = true;
    }
  }, [data,bmvisible]); 

  useEffect(()=>{
    console.log('Block Models rerendered')
  },[])

  return bmvisible ? (
    <group>
    <instancedMesh ref={instancedMeshRef} args={[null, null, count]}   userData={{instancesData:data}} name='bm' >
      <boxGeometry args={[4.9, 4.9, 4.9]}>
        <instancedBufferAttribute attach="attributes-color" args={[colors, 3]} />
      </boxGeometry>
      <meshLambertMaterial vertexColors toneMapped={false} />
    </instancedMesh> 
    </group>
  ) : null;

}

const fetchDataWithProgress = async (url) => {
  const response = await fetch(url);
  const reader = response.body.getReader();
  const contentLength = +response.headers.get('Content-Length');

  let receivedLength = 0;
  const chunks = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    chunks.push(value);
    receivedLength += value.length;

    console.log(`Received ${((receivedLength / contentLength) * 100).toFixed(2)}% of ${contentLength} bytes`);
  }

  return new Blob(chunks);
};
