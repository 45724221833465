import { useGLTF, Html } from "@react-three/drei";
import { Suspense, useEffect, useRef, useState, useMemo } from "react";
import JSZip from 'jszip';
import * as d3 from 'd3';
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from 'three';
import { useControls } from "leva";
import { useGraphStore } from "../../store/graphStore";
import { useShallow } from "zustand/react/shallow";
import { useSidebarStore } from "../../store/sidebarStore";

export default function BlastPlan() {

	const { showBlastStatistics, toggleComponent } = useSidebarStore();
	//LOAD DATA
	const url_plan = './models/blastdesign/OutputPath.zip';
	const url_actual = './models/blastdesign/ActualBlast.zip';
	const [originalSchedule, setOriginalSchedule] = useState([]);
	const [schedule, setSchedule] = useState([]);
	const [periodRange, setPeriodRange] = useState([1, 52]);
	const [actual, setActual] = useState([]);
	const [filteredSchedule, setFilteredSchedule] = useState([]);
	const [filteredNames, setFilteredNames] = useState([]);
	const [_Plan, set_Plan] = useState(false);
	const [LOM_Plan, setLOM_Plan] = useState(false);
	const [automate, setAutomate] = useState(false);
	const [weekRange, setWeekRange] = useState([1, 52]);
	const [summaries, setSummaries] = useState({
		WeekRange: [1, 1],
		OreTonnage: 0,
		goldMined: 0,
		OreGrade: 0,
		WasteVolume: 0,
	});
  const [originals, setOriginals] = useState({});

	const periodValues = useMemo(() => ({
		"all": [1, 52],
		"2024Q2": [14, 26],
		"2024Q3": [27, 39]
	}), []);

	// LOAD MODELS
	const originalModels = useGLTF('./models/blastdesign/BlastPlan.glb');
	const model_lom = useGLTF('./models/blastdesign/190624_LOM-transformed.glb');
	const modelsRef = useRef();

	// SETUP
	const { setGraphParams, setBlastReport } = useGraphStore(useShallow(state => ({
		setGraphParams: state.setGraphParams,
		setBlastReport: state.setBlastReport
	})));

	// Define a new goldMined
	const material = useMemo(() => new THREE.MeshStandardMaterial({
		color: 'blue',
		metalness: 0.5,
		roughness: 0.5,
	}), []);

	const [et, setET] = useState(0.0);
	const [tt, setTT] = useState(5000);

	const [{ controls }, set] = useControls('BlastPlan', () => ({
		showBlastStatistics:{
			value: showBlastStatistics,
      		onChange: (value) => toggleComponent(value),
		},
		Plan: {
			value: _Plan,
			onChange: (value) => set_Plan(value),
		},
		LOM_Plan: {
			value: LOM_Plan,
			onChange: (value) => setLOM_Plan(value),
		},
		period: {
			options: ["all", "2024Q2", "2024Q3"],
			onChange: (value) => setPeriodRange(periodValues[value]),
		},
		weekRange: {
			label: 'weekRange',
			min: periodRange[0],
			max: periodRange[1],
			value: weekRange,
			step: 1,
			format: (value) => value.toFixed(0),
			onChange: (value) => setWeekRange(value),
		},
		toggleAutomate: {
			label: 'Automate',
			value: automate,
			onChange: (value) => setAutomate(value),
		},
	}));

	useEffect(()=>{
		set({ showBlastStatistics: showBlastStatistics });
	},[])

	// Fetch and parse data
	const fetchData = async (url, setData) => {
		const blob = await fetchDataWithProgress(url);
		const zip = await JSZip.loadAsync(blob);
		const fileName = Object.keys(zip.files)[0];
		const fileData = await zip.file(fileName).async('string');
		const parsedData = d3.csvParse(fileData);
		setData(parsedData);
	};

	useEffect(() => {
		fetchData(url_plan, setOriginalSchedule);
	}, []);

	useEffect(() => {
		fetchData(url_actual, setActual);
	}, []);

	useEffect(() => {
		const ogs = {};
		originalModels.scene.traverse((object) => {
			if (object instanceof THREE.Mesh) {
				object.scale.set(0.99, 0.99, 0.99);
				ogs[object.name] = { y: object.position.y, mat: object.material };
			}
		});
		setOriginals(ogs);
	}, [originalModels]);

	useEffect(() => {
		model_lom.scene.traverse((object) => {
			if (object instanceof THREE.Mesh) {
				object.scale.set(0.99, 0.99, 0.99);
			}
		});
	}, [model_lom]);

	useEffect(() => {
		const newSchedule = originalSchedule.filter(
			(item) => item.Week >= periodRange[0] && item.Week <= periodRange[1]
		);
		setSchedule(newSchedule);
		setWeekRange([periodRange[0], periodRange[0]]);
	}, [originalSchedule, periodRange]);

	useEffect(() => {
		const models = modelsRef.current;
		if (originalModels && models) {
			while (models.children.length > 0) {
				models.remove(models.children[0]);
			}
			const scheduleNamesSet = new Set(schedule.map(item => item["Name_"]));
			const qualifyingObjects = originalModels.scene.children.filter((object) =>
				scheduleNamesSet.has(object.name)
			);
			qualifyingObjects.forEach((object) => {
				const clonedObject = object.clone();
				models.add(clonedObject);
			});
		}
	}, [schedule, originalModels, modelsRef,_Plan]);

	useEffect(() => {
		const oreTonnage = filteredSchedule
			.filter(item => item["Source Activity"] !== 'Waste' && item["Source Activity"] !== 'MG')
			.reduce((sum, item) => sum + Number(item["PO ExPitQty"]), 0);

		const goldMined = filteredSchedule
			.filter(item => item["Source Activity"] !== 'Waste' && item["Source Activity"] !== 'MG')
			.reduce((sum, item) => sum + (Number(item["PO ExPitQty"]) * Number(item["PO ExPitAu"])), 0);

		const wasteVolume = filteredSchedule
			.filter(item => item["Source Activity"] === 'Waste' || item["Source Activity"] === 'MG')
			.reduce((sum, item) => sum + Number(item["Primary Volume"]), 0);

		const oreGrade = goldMined / oreTonnage;

		setSummaries({
			WeekRange: weekRange,
			OreTonnage: oreTonnage,
			goldMined: goldMined,
			OreGrade: oreGrade,
			WasteVolume: wasteVolume,
		});
	}, [filteredSchedule]);

	useEffect(() => {
		let blastWeeklyData = {};
		filteredSchedule.forEach(blast => {
			const week = blast.Week;
			if (!blastWeeklyData[week]) {
				blastWeeklyData[week] = {
					week: week,
					oreTonnage: 0,
					goldMined: 0,
					oreGrade: 0,
					wasteVolume: 0,
				};
			}
			const weekData = blastWeeklyData[week];
			if (blast["Source Activity"] !== 'Waste' && blast["Source Activity"] !== 'MG') {
				weekData.oreTonnage += Number(blast["PO ExPitQty"]);
				weekData.goldMined += Number(blast["PO ExPitQty"]) * Number(blast["PO ExPitAu"]);
				weekData.oreGrade = weekData.goldMined / weekData.oreTonnage;
			} else {
				weekData.wasteVolume += Number(blast["Primary Volume"]);
			}
		});

		Object.values(blastWeeklyData).reduce((totals, weekData) => {
			totals.oreTonnage += weekData.oreTonnage;
			totals.goldMined += weekData.goldMined;
			totals.wasteVolume += weekData.wasteVolume;
			weekData.totalOreTonnage = totals.oreTonnage;
			weekData.totalgoldMined = totals.goldMined;
			weekData.totalOreGrade = totals.goldMined / totals.oreTonnage;
			weekData.totalWasteVolume = totals.wasteVolume;
			return totals;
		}, {
			oreTonnage: 0,
			goldMined: 0,
			wasteVolume: 0,
		});

		setGraphParams('line', 'BlastPlan', blastWeeklyData, 'week', {
			bars: ['oreTonnage', 'wasteVolume'],
			lines: ['totalOreTonnage', 'totalWasteVolume']
		});
	}, [filteredSchedule]);

	useEffect(() => {
		setBlastReport(`Weeks ${summaries.WeekRange[0]} - ${summaries.WeekRange[1]}`, 
			Number(summaries.OreTonnage).toFixed(0).toLocaleString(), 
			Number(summaries.OreGrade).toFixed(2).toLocaleString(), 
			Number(summaries.goldMined).toFixed(0).toLocaleString(), 
			Number(summaries.WasteVolume).toFixed(0).toLocaleString()
		);
	}, [summaries]);

	useEffect(() => {
		set({ weekRange: weekRange });
		const newFilteredSchedule = schedule.filter(
			(item) => item.Week >= weekRange[0] && item.Week <= weekRange[1]
		);
		setFilteredSchedule(newFilteredSchedule);
		const names = newFilteredSchedule.map((item) => item.Name_);
		setFilteredNames(names);
		setET(0);
	}, [weekRange]);

	useEffect(() => {
		let intervalId;
		if (automate) {
			intervalId = setInterval(() => {
				setET(0);
				setWeekRange((prev) => {
					const newMax = prev[1] + 1;
					if (newMax > periodRange[1]) {
						return [prev[0], prev[0]];
					} else {
						return [prev[0], newMax];
					}
				});
			}, tt);
		}
		return () => clearInterval(intervalId);
	}, [automate, periodRange]);

	useFrame((state, delta) => {
		if (!modelsRef.current) return;
		setET((et + delta * 1000) > tt ? 0 : (et + delta * 1000));
		modelsRef.current.children.forEach((object) => {
			if (object instanceof THREE.Mesh) {
				if (originals[object.name]) {
					if (filteredNames.includes(object.name)) {
						object.scale.x = Math.min(object.scale.x, 1 - et / tt);
						object.scale.z = object.scale.x;
						object.material = material;
						object.visible = object.scale.x > 0.05;
					} else {
						object.visible = true;
						object.scale.set(0.99, 0.99, 0.99);
						object.material = originals[object.name].mat;
					}
				}
			}
		});
	});

	return (
		<Suspense fallback={null}>
			{_Plan&&<group ref={modelsRef} name="models" />}
			{LOM_Plan && <primitive object={model_lom.scene} />}
		</Suspense>
	);
}

const fetchDataWithProgress = async (url) => {
	const response = await fetch(url);
	const reader = response.body.getReader();
	const contentLength = +response.headers.get('Content-Length');

	let receivedLength = 0;
	const chunks = [];

	while (true) {
	  const { done, value } = await reader.read();
	  if (done) break;

	  chunks.push(value);
	  receivedLength += value.length;

	  console.log(`Received ${((receivedLength / contentLength) * 100).toFixed(2)}% of ${contentLength} bytes`);
	}

	return new Blob(chunks);
};
