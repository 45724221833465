import { useGLTF } from "@react-three/drei"
import { useControls } from "leva"
import { useEffect } from "react";


const ModelLoader = ({group_,name_,url})=> {
    const model = useGLTF(url)
    const controlsConfig = {
        [name_]: false
    };

    useEffect(()=>{
        console.log(name_)
    })
    const controls = useControls(group_, controlsConfig);
    

    return (
      <>{controls[name_]&& <primitive object={model.scene} visible={controls[name_]}/> }</> 
    );
}

export default ModelLoader