import React, { PureComponent, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, ComposedChart, Area ,PieChart, Pie, Cell} from 'recharts';
import { useGraphStore } from '../../store/graphStore';
import { useShallow } from 'zustand/react/shallow';

const colors = ["#00ff00", "#ff0000", "#ff7300", "#ff0000", "#00ff00", "#0000ff"]; 
const COLORS = ["#0000FF", "#2600E6", "#4C00CC", "#7200B3", "#990099", "#BF007F", "#E50066", "#FF004C", "#FF0033", "#FF0019", "#FF0000", "#D40000", "#AA0000"]

export default function PieGraph() {

  const {graphType,graphTitle,graphData,graphX,graphY} = useGraphStore(useShallow((state)=>({
    graphType: state.graphType,
    graphTitle:state.graphTitle,
    graphData:state.graphData,
    graphX:state.graphX,
    graphY:state.graphY,
  })))

  useState(()=>{
    console.log(Object.values(graphData))
  
    
  },[graphData])

  class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
  
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
          {Number(value).toFixed(1)}
        </text>
      );
    }
  }


  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart
        width={500}
        height={300}
      > 
        {
          graphY['bars']?graphY['bars'].map((item1,index1)=>{
            return (
              <>
                <Pie
                  data={Object.values(graphData)}
                  cx={120+index1*200}
                  cy={200}
                  startAngle={180}
                  endAngle={-150}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey={item1}
                  //labelLine={false}
                  label={renderCustomizedLabel}
                  // label
                >        
                  {Object.values(graphData).map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip  content={CustomTooltip}  />
              </>
            )          
          }):null
        }
        
      </PieChart>
      </ResponsiveContainer>
  );
  
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log(payload)
    return (
      <div className="p-4 bg-slate-900 flex flex-col gap-4 rounded-md">
        <p className="text-medium text-lg text-white">{` ${'week '+payload[0].payload.week}`}</p>
            <p className="text-sm text-blue-400">
              {payload[0].dataKey}:
            <span className="sm-2">{Number(payload[0].value).toFixed(0)}</span>
            </p>
      </div>
    );
  }

  return null;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} fontSize={8} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
