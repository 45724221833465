import React from 'react'
import { useInforStore } from '../store/clickStore.js';
import { useShallow } from 'zustand/react/shallow';

export default function Info() {
    
  const {info} = useInforStore(useShallow((state)=>({
      info:state.info
  })))
  return (
    <div id='info'>
        {info}
    </div>
  )
}
