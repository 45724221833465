export const ModelListPK = [
  
    {
        'group':'Drone',
        'name':'Pit',
        'models':
        [
            {name:'eu_pit',url:'./models/pit/eu_pit-transformed.glb'},
        ]   

    },
    {
        'group':'Drone',
        'name':'Dumps',
        'models':
        [
            {name:'SouthDump',url:'./models/dumps/south_dump/300623-transformed.glb'},
            {name:'NorthWestDump',url:'./models/dumps/north_west_dump/300623-transformed.glb'}
        ]   

    },
    {
        'group':'Drone',
        'name':'Tailings',
        'models':
        [
            {name:'TSF',url:'./models/tsf/190123-transformed.glb'},
        ]   

    },
    {
        'group':'Drone',
        'name':'Stockpiles',
        'models':
        [
            {name:'Stockpile',url:'./models/stockpile/070723-transformed.glb'},
        ]   

    },
    {
        'group':'Plan',
        'name':'LOM',
        'models':
        [
            {name:'PB1',url:'./models/plan/PB1.glb'},
            {name:'PB2',url:'./models/plan/PB2.glb'},
            {name:'PB3',url:'./models/plan/PB3.glb'},
            {name:'PitDesign',url:'./models/plan/EUREKA_PIT_DESIGN_.glb'},
            {name:'UGDevelopmemt',url:'./models/plan/EUREKA_UG_PLANNED_DEVELOPMENT_-transformed.glb'},
            {name:'UGStopes',url:'./models/plan/EUREKA_UG_PLANNED_STOPES.glb'},
            ]   

    },
    {
        'group':'Geology',
        'name':'Orebodies',
        'models':
        [
            {name:'molly',url:'./models/orebodies/molly.glb'},
            {name:'molly2',url:'./models/orebodies/molly2.glb'},
            {name:'molly3',url:'./models/orebodies/molly3.glb'},
            {name:'molly4',url:'./models/orebodies/molly4.glb'},
            {name:'molly5',url:'./models/orebodies/molly5.glb'},
            {name:'satellite',url:'./models/orebodies/satellitebody.glb'},
            {name:'satellite2',url:'./models/orebodies/satellite2.glb'},
            {name:'satellite10',url:'./models/orebodies/satelliteten.glb'},
            {name:'streaky',url:'./models/orebodies/streaky.glb'},
            {name:'streaky2',url:'./models/orebodies/streaky2.glb'},
            {name:'zebra',url:'./models/orebodies/zebra.glb'},
            {name:'zebra1',url:'./models/orebodies/zebra1.glb'},
            {name:'zebra2',url:'./models/orebodies/zebra2.glb'},
        ]   

    },
    {
        'group':'Survey',
        'name':'Blast',
        'models':
        [
            {name:'Jan2023',url:'./models/blasts/Jan2023.glb'},
            {name:'Feb2023',url:'./models/blasts/Feb2023.glb'},
            {name:'Mar2023',url:'./models/blasts/Mar2023.glb'},
            {name:'Apr2023',url:'./models/blasts/Apr2023.glb'},
            {name:'May2023',url:'./models/blasts/May2023.glb'},
            {name:'Jun2023',url:'./models/blasts/Jun2023.glb'},
            {name:'Sep2023',url:'./models/blasts/Sept2023.glb'},
            {name:'Apr2024',url:'./models/blasts/Apr2024.glb'},
            {name:'May2024',url:'./models/blasts/May2024.glb'},
            {name:'Jun2024',url:'./models/blasts/Jun2024.glb'},
        ]   

    },
    {
        'group':'Survey',
        'name':'UGWorkings',
        'models':
        [
            {name:'Level1',url:'./models/underground/l1_dev.glb'},
            {name:'Level2',url:'./models/underground/l2_dev.glb'},
            {name:'Level3',url:'./models/underground/l3_dev.glb'},
            {name:'Level4',url:'./models/underground/l4_dev.glb'},
        ]   

    }
    

    
]