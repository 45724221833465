import React from 'react'
import { useSidebarStore } from "../../store/sidebarStore";
import BlastStatistics from '../../BlastStatistics';
import ObjectDetailsPage from './ObjectDetailsPage';

export default function LeftBar() {
    const { showBlastStatistics } = useSidebarStore();

  return (
     showBlastStatistics ? <BlastStatistics /> : <ObjectDetailsPage />
  )
}
