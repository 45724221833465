import React, { useEffect } from 'react'
import LineGraph from './components/sidebar/LineGraph'
import PieGraph from './components/sidebar/PieGraph'
import BlastReport from './components/sidebar/BlastReport'
import BlastChart from './components/sidebar/BlastChart'

export default function BlastStatistics
() {
    useEffect(()=>{
        console.log('BlastStatistics rerendered')
    },[])

  return (
    // <div className='overflow-y-auto h-full'>
        
    //     <BlastChart/>
        
    //     <div className='grid xl:grid-cols-1 lg:grid-cols1 w-full gap-5 max-w-[1400px]'>
    //         <GridItem title="Weekly Plan">
    //             <LineGraph/>
    //         </GridItem>
    //         <GridItem title="Distribution Of Ore and Waste">
    //             <PieGraph/>
    //         </GridItem>           
    //     </div>

        
    // </div>
    <div className={"flex flex-col absolute inset-0 my-auto overflow-y-auto h-full   bg-gray-100 p-1"}>
    <div className="flex justify-between items-center">
      <h2 className="text-xl font-semibold">{'PK UNDERGROUND STOPE SATISTICS'}</h2>
    </div>
    
    <BlastChart/> 
  </div>
  )
}


function GridItem({title,children}){
    return(
        <div className='flex flex-col items-center justify-center  border border-slate-900 bg-indigo-950 rounded-md h-[400px]  shadow-lg'>
            <h3 className='text-xl font-semibold text-white mb-1'>{title}</h3>
            {children}
        </div>
    )
}

