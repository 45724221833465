import { create } from "zustand";

export const useGraphStore =  create((set)=>({
    graphType:'Line',
    graphData:{},
    graphX:'',
    graphY:{},
    graphTitle:'',
    setGraphParams:(graphType,graphTitle,graphData,graphX,graphY)=>{
        set((state)=>({
            graphType: graphType,
            graphTitle:graphTitle,
            graphData:graphData,
            graphX:graphX,
            graphY:graphY,
        }))
    },
    oreTonnage:'0',
    oreGrade:'0',
    goldMined:'0',
    wasteVolume:'0',
    range:'0',
    setBlastReport:(range,oreTonnage,oreGrade,goldMined,wasteVolume)=>{
        set((state)=>({
            range:range,
            oreTonnage:oreTonnage,
            oreGrade:oreGrade,
            goldMined:goldMined,
            wasteVolume:wasteVolume
        }))
    }

}));
