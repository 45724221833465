import { useEffect, useState} from "react";
import JSZip from 'jszip';
import * as d3 from 'd3';
import { useSidebarStore } from "../../store/sidebarStore";
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, ComposedChart, Area } from 'recharts';
import MultiSelectDropdown from '../multiselect/MultiSelectDropdown';


export default function BlastChart({BlastID}) {
    

    const [data, setData] = useState([]);
    const [alldata, setAllData] = useState({});

	const volumeToTonnageFactor = 2.72
	const { showBlastStatistics, toggleComponent } = useSidebarStore();
	//LOAD DATA
	const url_plan = './models/blastdesign/OutputPath.zip';
	const url_actual = './models/blastdesign/ActualBlast.zip';
	const [originalSchedule, setOriginalSchedule] = useState(null);
	const [filteredSchedule, setFilteredSchedule] = useState(null);
	const [originalActual, setOriginalActual] = useState(null);
	const [filteredActual, setFilteredActual] = useState(null);
	const [summary_field, setSummaryField] = useState('Week');
		
	const [blastFilterData, setBlastFilterData] = useState([[],'Week',[]]);

	// Fetch and parse data
	const fetchData = async (url, setData) => {
		const blob = await fetchDataWithProgress(url);
		const zip = await JSZip.loadAsync(blob);
		const fileName = Object.keys(zip.files)[0];
		const fileData = await zip.file(fileName).async('string');
		const parsedData = d3.csvParse(fileData);
		setData(parsedData);
	};

	useEffect(() => {
		fetchData(url_plan, setOriginalSchedule);
	}, []);

	useEffect(() => {
		fetchData(url_actual, setOriginalActual);
	}, []);

	useEffect(()=>{
		if(!originalSchedule) return
		if(!originalActual) return

		let filteredScheduleMid = originalSchedule
		let filteredActualMid = originalActual

		if (BlastID) {
			filteredScheduleMid = originalSchedule.filter(row => row['Name'] === BlastID);
			filteredActualMid = originalActual.filter(row => row['Name'] === BlastID);
		}
	
		if(blastFilterData[2].length>0){
			filteredScheduleMid = filteredScheduleMid.filter(row => blastFilterData[2].includes(row[blastFilterData[1]]));
		}

		if(blastFilterData[2].length>0){
			filteredActualMid = filteredActualMid.filter(row => blastFilterData[2].includes(row[blastFilterData[1]]));
		}

		setFilteredSchedule(filteredScheduleMid)
		setFilteredActual(filteredActualMid)

	},[originalSchedule,originalActual,blastFilterData])

	useEffect(() => {

		if(!filteredActual) return
		if(!filteredSchedule) return

		const summary = {};
		const totalSummary = {
			Planned_WasteVolume: 0,
			Mined_WasteVolume: 0,
			Planned_OreTonnage: 0,
			Mined_OreTonnage: 0,
			Planned_Grade: 0,
			Mined_Grade: 0,
			Planned_Content: 0,
			Actual_Content: 0,
		};

		filteredSchedule.forEach(blast => {
			const key = blast[summary_field];
			if (!summary[key]) {
			  summary[key] = {
				[summary_field]:key,
				Planned_WasteVolume: 0,
				Mined_WasteVolume: 0,
				Planned_OreTonnage: 0,
				Mined_OreTonnage: 0,
				Planned_Grade: 0,
				Mined_Grade: 0,
				Planned_Content: 0,
				Actual_Content: 0
			  };
			}

			if (blast["Source Activity"] !== 'Waste' && blast["Source Activity"] !== 'MG') {
				summary[key].Planned_OreTonnage += Number(blast["PO ExPitQty"]);
				summary[key].Planned_Content += Number(blast["PO ExPitQty"]) * Number(blast["PO ExPitAu"]);
				summary[key].Planned_Grade = summary[key].Planned_Content / summary[key].Planned_OreTonnage?summary[key].Planned_Content / summary[key].Planned_OreTonnage:summary[key].Planned_Grade;

				totalSummary.Planned_OreTonnage += Number(blast["PO ExPitQty"]);
				totalSummary.Planned_Content += Number(blast["PO ExPitQty"]) * Number(blast["PO ExPitAu"]);
				totalSummary.Planned_Grade = totalSummary.Planned_Content / totalSummary.Planned_OreTonnage?totalSummary.Planned_Content / totalSummary.Planned_OreTonnage:totalSummary.Planned_Grade;
			} else {
				summary[key].Planned_WasteVolume += Number(blast["Primary Volume"]);

				totalSummary.Planned_WasteVolume += Number(blast["Primary Volume"]);
			}
	
		});

		filteredActual.forEach(blast => {
			const key = blast[summary_field];
			if (!summary[key]) {
				summary[key] = {
				[summary_field]:key,
				Planned_WasteVolume: 0,
				Mined_WasteVolume: 0,
				Planned_OreTonnage: 0,
				Mined_OreTonnage: 0,
				Planned_Grade: 0,
				Mined_Grade: 0,
				Planned_Content: 0,
				Actual_Content: 0,
				};
			}

			if ((blast["Blasted Material"]).toLowerCase() == 'ore') {
				summary[key].Mined_OreTonnage += Number(blast["Payable BCMs"])*volumeToTonnageFactor;
				summary[key].Actual_Content += Number(blast["Payable BCMs"])*volumeToTonnageFactor * Number(blast["Grade"]);
				summary[key].Mined_Grade = summary[key].Actual_Content / summary[key].Mined_OreTonnage?summary[key].Actual_Content / summary[key].Mined_OreTonnage:summary[key].Mined_Grade;
				
				totalSummary.Mined_OreTonnage += Number(blast["Payable BCMs"])*volumeToTonnageFactor;
				totalSummary.Actual_Content += Number(blast["Payable BCMs"])*volumeToTonnageFactor * Number(blast["Grade"]);
				totalSummary.Mined_Grade = totalSummary.Actual_Content / totalSummary.Mined_OreTonnage?totalSummary.Actual_Content / totalSummary.Mined_OreTonnage:totalSummary.Mined_Grade;
			} else {
				summary[key].Mined_WasteVolume += Number(blast["Payable BCMs"]);

				totalSummary.Mined_WasteVolume += Number(blast["Payable BCMs"]);
			}	
		});

		setAllData(totalSummary)
		setData(summary);

		console.log(summary)
		console.log(filteredActual)
	
	}, [BlastID, summary_field,filteredSchedule,filteredActual]);


	// useEffect(() => {
	// 	setBlastReport(`Weeks ${summaries.WeekRange[0]} - ${summaries.WeekRange[1]}`, 
	// 		Number(summaries.OreTonnage).toFixed(0).toLocaleString(), 
	// 		Number(summaries.OreGrade).toFixed(2).toLocaleString(), 
	// 		Number(summaries.goldMined).toFixed(0).toLocaleString(), 
	// 		Number(summaries.WasteVolume).toFixed(0).toLocaleString()
	// 	);
	// }, [summaries]);


	return (
		<>
			
			<div className="p-2 bg-white shadow rounded flex space-x-4">
				{!BlastID && <Card parsedData={originalSchedule} setFilterData={setBlastFilterData}/> }
				<div className="flex items-center px-1 border m-1 border-black bg-violet-200 text-xs  ">
					<label className="text-gray-700">X: </label>
					<select
						value={summary_field}
						onChange={(e) => setSummaryField(e.target.value)}
						className="focus:outline-none focus:ring-2 focus:ring-blue-500 bg-violet-200"
					>
						<option value="Week">Week</option>
						<option value="Month">Month</option>
						<option value="Quarter">Quarter</option>
						<option value="Year">Year</option>
						<option value="Name">Blast</option>
					</select>
				</div>
			</div>

			<div className='grid xxl:grid-cols-2 xl:grid-cols-4 lg:grid-cols-4 w-full gap-2 max-w-[1400px] py-2'>
				<GridItem1 title="Planned Ore Tonnage" value={Number(alldata.Planned_OreTonnage).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'t'}> </GridItem1>
				<GridItem1 title="Mined Ore Tonnage" value={Number(alldata.Mined_OreTonnage).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'t'}> </GridItem1>
				<GridItem1 title="Planned Grade" value={Number(alldata.Planned_Grade).toLocaleString("en-EN", { maximumFractionDigits: 2 })}  prefix={'g/t'}> </GridItem1>
				<GridItem1 title="Actual Grade" value={Number(alldata.Mined_Grade).toLocaleString("en-EN", { maximumFractionDigits: 2 })}  prefix={'g/t'}> </GridItem1>
				<GridItem1 title="Planned Gold" value={Number(alldata.Planned_Content).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'g'}> </GridItem1>
				<GridItem1 title="Mined Gold" value={Number(alldata.Actual_Content).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'g'}> </GridItem1>
				<GridItem1 title="Planned Waste" value={Number(alldata.Planned_WasteVolume).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'bcm'}> </GridItem1>
				<GridItem1 title="Mined Waste" value={Number(alldata.Mined_WasteVolume).toLocaleString("en-EN", { maximumFractionDigits: 0 })}  prefix={'bcm'}> </GridItem1>
			</div> 

			<GridItem title="Ore Tons. Trend" key={1}>
				<SummaryChart
				BlastID={BlastID}
				summary_field={summary_field}
				data={data}
				target_fields={
					[
					{ column_name: 'Planned Ore Tonnage (t)', legend_name: 'Planned' , graphKey:'Planned_OreTonnage' },
					{ column_name: 'Mined Ore Tonnage (t)', legend_name: 'Mined', graphKey:'Mined_OreTonnage'  }
					]
				}
				graphOptions={{
					graph_type: 'BarChart', // Example graph type
					graph_title: 'Ore Source Reconciliation',
					x_title:  'Period',
					y_title: 'Tonnage (t)'
				}}
				/>
			</GridItem>  

			<GridItem title="Ore Grade Trend" key={2}>
				<SummaryChart
				BlastID={BlastID}
				summary_field={summary_field}
				data={data}
				target_fields={
					[
					{ column_name: 'Planned Grade (g/t)', legend_name: 'Planned' , graphKey:'Planned_Grade' },
					{ column_name: 'Mined Grade (g/t)', legend_name: 'Mined', graphKey:'Mined_Grade'  }
					]
				}
				graphOptions={{
					graph_type: 'BarChart', // Example graph type
					graph_title: 'Ore Source Reconciliation',
					x_title: 'Period',
					y_title: 'Grade (g/t)'
				}}
				/>
			</GridItem> 

			<GridItem title="Gold Trend" key={3}>
				<SummaryChart
				BlastID={BlastID}
				summary_field={summary_field}
				data={data}
				target_fields={
					[
					{ column_name: 'Planned Grade (g/t)', legend_name: 'Planned' , graphKey:'Planned_Content' },
					{ column_name: 'Mined Grade (g/t)', legend_name: 'Mined', graphKey:'Actual_Content'  }
					]
				}
				graphOptions={{
					graph_type: 'BarChart', // Example graph type
					graph_title: 'Gold Trend',
					x_title: 'Period',
					y_title: 'Gold (g)'
				}}
				/>
			</GridItem> 

			<GridItem title="Waste Trend" key={4}>
				<SummaryChart
				BlastID={BlastID}
				summary_field={summary_field}
				data={data}
				target_fields={
					[
					{ column_name: 'Planned Grade (g/t)', legend_name: 'Planned' , graphKey:'Planned_WasteVolume' },
					{ column_name: 'Mined Grade (g/t)', legend_name: 'Mined', graphKey:'Mined_WasteVolume'  }
					]
				}
				graphOptions={{
					graph_type: 'BarChart', // Example graph type
					graph_title: 'Waste Tren',
					x_title: 'Period',
					y_title: 'Volume (bcm)'
				}}
				/>
			</GridItem> 
			

		</>
	);
}

const SummaryChart = ({ data, summary_field, target_fields, graphOptions })=>{
	
	const colors = ["#00ff00", "#ff0000", "#ff7300", "#ff0000", "#00ff00", "#0000ff"]; 
	return(
	  <ResponsiveContainer width="100%" height="100%">
	  <ComposedChart
		width={500}
		height={500}
		data={Object.values(data)}
		margin={{
		  top: 20,
		  right: 10,
		  bottom: 20,
		}}
	  >
		<CartesianGrid strokeDasharray="5 5" />
		<XAxis dataKey={summary_field}  fontSize={10}  tick={{ fill: 'white' }} label={{ value: summary_field, position: 'insideBottomRight', offset: 0 }} />
		<YAxis  fontSize={10}  tick={{ fill: 'white' }} label={{ value: graphOptions.y_title, angle: -90, position: 'insideLeft' }} />      
		<Tooltip content={CustomTooltip} />
		<Legend fontSize={8} />
	  
		{target_fields.map((field,index) => (
		  <Bar key={field.graphKey} fill={colors[index]} barSize={20} dataKey={field.graphKey} name={field.legend_name}/>
		))} 
	  </ComposedChart>
	  </ResponsiveContainer>
	)
  }
  
  const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
	  //console.log(payload)
	  return (
		<div className="p-1 bg-slate-900 flex flex-col gap-1 rounded-md">
		  <p className="text-medium text-sm text-white">{` ${''+label}`}</p>
		  {
			payload.map((item,index)=>{
			  return(
			  <p className="text-sm text-blue-400" key={index}>
				{item.name}:
			  <span className="sm-2">{ Number(item.value).toLocaleString("en-EN", { maximumFractionDigits: item.name.endsWith('Grade')?2:0 })}</span>
			  </p>
			  )
			})
		  }
		  
		  
		</div>
	  );
	}
  
	return null;
  };
  
  function GridItem({title,children}){
	return(
		<div className='flex flex-col items-center justify-center my-2  border border-slate-900 bg-indigo-950 rounded-md h-[400px]  shadow-lg min-h-52'>
            <h3 className='text-xl font-semibold text-white mb-1'>{title}</h3>
            {children}
        </div>
	)
  }

function GridItem1({title,value,prefix}){
	return(
		<div className='flex flex-col items-center justify-between  border h-auto rounded overflow-hidden p-1 bg-white'>
			<h3 className='text-xs font-semibold text-green-800 justify-center '>{title}</h3>
			<h3 className='text-xs font-semibold text-orange-600 '>{value}  <span className='text-sm font-thin text-orange-300' >{prefix}</span></h3>
		</div>
	)
  }

const fetchDataWithProgress = async (url) => {
	const response = await fetch(url);
	const reader = response.body.getReader();
	const contentLength = +response.headers.get('Content-Length');

	let receivedLength = 0;
	const chunks = [];

	while (true) {
	  const { done, value } = await reader.read();
	  if (done) break;

	  chunks.push(value);
	  receivedLength += value.length;

	  console.log(`Received ${((receivedLength / contentLength) * 100).toFixed(2)}% of ${contentLength} bytes`);
	}

	return new Blob(chunks);
};

const Card = ({ parsedData,setFilterData}) => {

	const [period, setPeriod] = useState('Week');
	const [selectedPeriod , setSelectedPeriod] = useState([])
	const [selectPeriodOptions , setSelectPeriodOptions] = useState([])  

    
	
  	const periods = ['Week','Month', 'Quarter', 'Year'];
  
	const handlePeriodChange = (e) => {
	  setPeriod(e.target.value);
	};

    useEffect(()=>{
        console.log(period,selectedPeriod)
        setFilterData([[],period,selectedPeriod])
      },[period,selectedPeriod])
  
	useEffect(()=>{        
        if(!parsedData) return
        if(!parsedData.length>0) return

        setSelectedPeriod([])
        console.log([...new Set(parsedData.map((item) => item[period]))])
        setSelectPeriodOptions([...new Set(parsedData.map((item) => item[period]))])
	},[period])
  
  
	const itemClickedPeriod = (item) => {
	  setSelectedPeriod((prevSelected) => 
		prevSelected.includes(item) 
		  ? prevSelected.filter((i) => i !== item) 
		  : [...prevSelected, item]
	  );
	};
  
	return (
	//   <div className="p-2 bg-white shadow rounded">
	// 	<div className="mb-2  mt-1 block w-full">
	// 	  <div className='cursor-pointer border-solid p-2 border-stone-500 shadow-lg w-full border'>
	// 		{/* <label className="block text-gray-700">Period</label> */}
	// 		<select value={period} onChange={handlePeriodChange} className="mt-1 block w-full">
	// 		  {periods.map((prd) => (
	// 			<option key={prd} value={prd}>
	// 			  {prd}
	// 			</option>
	// 		  ))}
	// 		</select>
	// 	  </div>
	// 	</div>
	// 	<div className='mb-2 mt-1 block w-full'>
	// 	  <MultiSelectDropdown label={'Options'} list={selectPeriodOptions} selectedlist={selectedPeriod} itemClick={itemClickedPeriod} />
	// 	</div>
  
	//   </div>
			<>
			<div className="mt-1 block w-full">
				<div className="cursor-pointer border-solid border-stone-500 shadow-lg w-full border text-xs">
					<select value={period} onChange={handlePeriodChange} className="p-2 block w-full text-xs">
						{periods.map((prd) => (
							<option key={prd} value={prd}>
								{prd}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="mb-2 mt-1 block w-full">
				<MultiSelectDropdown label={'Options'} list={selectPeriodOptions} selectedlist={selectedPeriod} itemClick={itemClickedPeriod} />
			</div>
			</>
	);
  };
  
