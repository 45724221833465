import React, { useEffect, useState } from 'react'
import { useClickStore } from '../../store/clickStore';
import { useShallow } from 'zustand/react/shallow';
import SidebarScene from './SidebarScene';

export default function ObjectDetailsPage() {
    const [data,setData] = useState([]);

      const {objectDetails,objectDetailsPosition,objectDetailsObject,clickType} = useClickStore(useShallow((state)=>({
        objectDetails:state.objectDetails,
        objectDetailsPosition:state.objectDetailsPosition,
        objectDetailsObject:state.objectDetailsObject,
        clickType:state.clickType,
    })))

    useEffect(()=>{
        let detailsArr = []
        if(objectDetails){
            Object.entries(objectDetails).map(([key, value]) => (
                detailsArr.push({
                    name:key,
                    value:value
                })
            ))
        }

        setData(detailsArr)
    },[objectDetails])

    const rows = objectDetails? Object.entries(objectDetails).map(([key, value]) => (
        <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
        </tr>
    )):<></>;

  return (
    <div className={"absolute inset-0 my-auto overflow-y-auto h-full flex flex-col  bg-gray-100 p-1"}>
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">{objectDetails?objectDetails.Name:'Object details'}</h2>
      </div>
      
      <div className='h-96 w-full sidebarscene rounded-md z-10 shadow-lg cursor-pointer'>
        <SidebarScene objectDetailsObject={objectDetailsObject} />
      </div>
      
      
      <h3 className="text-sm text-gray-900 font-medium mt-2">Description of object</h3>
      <div className="flex justify-between items-center">
        <p className="text-gray-600 text-sm">Thu, Jul 11, 2024 9:15 PM - 10:05 PM</p>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded inline-flex text-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-1 mr-3 h-6 w-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15l7.5-7.5m0 0l7.5 7.5m-7.5-7.5V21" />
        </svg>
        Copy
      </button>
      </div>

      <div className="overflow-x-auto mt-4">
      <table className="w-full table-auto">
          <thead className="bg-white sticky top-0">
            <tr>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-300' : 'bg-white'}>
                <td className="border px-4 py-2">{item.name}</td>
                <td className="border px-4 py-2">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}
